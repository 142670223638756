import type { Environment } from './types';
import { sharedProdEnv, sharedJuniperUkEnv, sharedProdEuEnv } from './shared';

export const env: Environment = {
  ...sharedProdEnv,
  ...sharedJuniperUkEnv,
  ...sharedProdEuEnv,
  adminAppUrl: 'https://admin.myjuniper.co.uk',
  logoUrl: 'https://assets.myjuniper.co.uk/juniper-logo.svg',
};
